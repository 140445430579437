import React, { useEffect } from 'react';
import AOS from 'aos';
import "aos/dist/aos.css";
import './index.css';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate
} from 'react-router-dom';
import { Helmet } from 'react-helmet'; // Importing React Helmet

// All pages
import Home from './pages/Home';
import Contact from './pages/Contact';
import DemoProduct from './pages/DemoProduct';

import { useDocTitle } from './components/CustomHook';
import ScrollToTop from './components/ScrollToTop';
import About from './components/About/about';
import ServicePage from './components/Service/Service';
import Career from './components/Career';
import { BlogPageOne } from './components/Blog';

function App() {
  useEffect(() => {
    const aos_init = () => {
      AOS.init({
        once: true,
        duration: 1000,
        easing: 'ease-out-cubic',
      });
    };

    window.addEventListener('load', () => {
      aos_init();
    });
  }, []);

  // Remove useDocTitle and manage it with React Helmet
  // useDocTitle(" Masterwiz Technology ");

  return (
    <>
      <Helmet>
        <title>Masterwiz Technology-Top Tech Company in Varanasi, India</title>
        <meta name="description" content="Masterwiz Technology offers end-to-end software services for startups, including web and mobile app development." />
        <meta name="keywords"
          content="tech company in Varanasi,top tech company in Varanasi,top 10 tech company in Varanasi,best tech company in Varanasi, digital company in Varanasi, best IT company in Varanasi, software development in Varanasi, e-commerce solutions Varanasi, fantasy sports tech company, top tech company in Uttar Pradesh, web development in Varanasi, mobile app development in Varanasi, startup tech company in Varanasi, custom software services Varanasi, Masterwiz Technologies, digital transformation services, IT solutions in Varanasi, marketing solutions Varanasi" />
          <link rel="canonical" href="https://www.masterwiztechnologies.com" />
        {/* Add any additional meta tags you need */}
      </Helmet>

      <Router>
        <ScrollToTop>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/services" element={<ServicePage />} />
            <Route path="/careers" element={<Career />} />
            <Route path="/blogs" element={<BlogPageOne />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/get-demo" element={<DemoProduct />} />
            {/* Redirect to home page for all undefined routes */}
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </ScrollToTop>
      </Router>
    </>
  );
}

export default App;
