import React, { useState } from 'react';
import Footer from './Footer';
import NavBar from './Navbar/NavBar';
import Cta from './Cta';
import { Helmet } from 'react-helmet';

const posts = [
    {
        category: 'AI & Machine Learning',
        title: 'The Rise of AI: How It’s Changing the Tech Industry',
        description: 'Explore the profound impact of artificial intelligence on various sectors, from healthcare to finance, and how it’s transforming the entire tech landscape. Understand the key drivers behind AI advancements and what the future holds for this revolutionary technology.',
        author: 'Sophia Wang',
        date: '3 August 2023',
        avatar: 'https://via.placeholder.com/48',
        poster: 'https://via.placeholder.com/600x400',
    },
    {
        category: 'Cybersecurity',
        title: 'Top 10 Security Practices for Modern Web Development',
        description: 'Discover essential security practices to protect your web applications from modern threats and vulnerabilities. Learn about encryption, secure coding, and the importance of staying updated with the latest security protocols to safeguard your digital assets.',
        author: 'Liam Carter',
        date: '1 August 2023',
        avatar: 'https://via.placeholder.com/48',
        poster: 'https://via.placeholder.com/600x400',
    },
    {
        category: 'Cloud Computing',
        title: 'Why Cloud-Native is the Future of Software Development',
        description: 'Understand the advantages of cloud-native architectures, including scalability, flexibility, and cost-efficiency. Explore how cloud-native solutions are reshaping the software development process and enabling businesses to innovate faster and more effectively.',
        author: 'Ava Green',
        date: '28 July 2023',
        avatar: 'https://via.placeholder.com/48',
        poster: 'https://via.placeholder.com/600x400',
    },
    {
        category: 'Cloud Computing',
        title: 'Continuous Integration and Continuous Delivery Explained',
        description: 'Learn the basics of CI/CD and how these practices contribute to faster, more reliable software releases. Delve into the tools and techniques that make CI/CD pipelines essential for modern software development, reducing the time from development to deployment.',
        author: 'James Miller',
        date: '25 July 2023',
        avatar: 'https://via.placeholder.com/48',
        poster: 'https://via.placeholder.com/600x400',
    },
    {
        category: 'Blockchain',
        title: 'Blockchain Beyond Cryptocurrency: Real-World Applications',
        description: 'Explore how blockchain technology is being used beyond digital currencies, with real-world applications in supply chain management, healthcare, and data security. Understand how blockchain can offer transparency, security, and efficiency in various industries.',
        author: 'Olivia Martinez',
        date: '22 July 2023',
        avatar: 'https://via.placeholder.com/48',
        poster: 'https://via.placeholder.com/600x400',
    },
    {
        category: 'Development',
        title: 'Best Practices for Writing Clean and Maintainable Code',
        description: 'Learn the principles of clean code, including readability, simplicity, and modularity. Discover how applying these best practices can improve your codebase, making it easier to maintain, scale, and collaborate on with other developers.',
        author: 'Lucas Brown',
        date: '19 July 2023',
        avatar: 'https://via.placeholder.com/48',
        poster: 'https://via.placeholder.com/600x400',
    },
    {
        category: 'AI & Machine Learning',
        title: 'The Ethics of AI: Balancing Innovation and Responsibility',
        description: 'Discuss the ethical considerations of AI development, including bias, privacy, and the societal implications of autonomous systems. Understand how to balance the pursuit of innovation with the responsibility to create ethical and fair AI solutions.',
        author: 'Isabella Davis',
        date: '16 July 2023',
        avatar: 'https://via.placeholder.com/48',
        poster: 'https://via.placeholder.com/600x400',
    },
    {
        category: 'Development',
        title: 'The Future of Front-End Development: Trends to Watch',
        description: 'Stay ahead of the curve by learning about the latest trends in front-end development, such as Progressive Web Apps (PWAs), WebAssembly, and server-side rendering (SSR). Explore how these trends are shaping the future of web development.',
        author: 'Mason Hill',
        date: '13 July 2023',
        avatar: 'https://via.placeholder.com/48',
        poster: 'https://via.placeholder.com/600x400',
    },
    {
        category: 'Marketing Technology',
        title: 'The Role of AI in Digital Marketing: A Game Changer',
        description: 'Discover how AI is revolutionizing digital marketing by enabling personalized experiences, automating customer interactions, and enhancing data analytics. Learn how AI-driven tools are helping marketers optimize their strategies and achieve better results.',
        author: 'Emma Johnson',
        date: '10 July 2023',
        avatar: 'https://via.placeholder.com/48',
        poster: 'https://via.placeholder.com/600x400',
    },
    {
        category: 'Marketing Technology',
        title: 'Data-Driven Marketing: Leveraging Big Data for Success',
        description: 'Explore how big data is transforming marketing strategies by providing deeper insights into consumer behavior and preferences. Learn how to harness the power of data analytics to create targeted, effective marketing campaigns that resonate with your audience.',
        author: 'Noah Wilson',
        date: '7 July 2023',
        avatar: 'https://via.placeholder.com/48',
        poster: 'https://via.placeholder.com/600x400',
    },
    {
        category: 'AI & Machine Learning',
        title: 'Personalization at Scale: How AI is Redefining Customer Experiences',
        description: 'Understand how AI is enabling businesses to deliver personalized experiences at scale, from product recommendations to tailored marketing messages. Explore the tools and techniques that are making it possible to create highly relevant customer interactions.',
        author: 'Sophia Wang',
        date: '4 July 2023',
        avatar: 'https://via.placeholder.com/48',
        poster: 'https://via.placeholder.com/600x400',
    },
    {
        category: 'AI & Machine Learning',
        title: 'The Convergence of AI and IoT: Transforming Industries',
        description: 'Learn how the convergence of AI and the Internet of Things (IoT) is driving innovation across industries. Explore real-world examples of AI-powered IoT solutions that are improving efficiency, reducing costs, and enabling smarter decision-making.',
        author: 'Oliver Smith',
        date: '1 July 2023',
        avatar: 'https://via.placeholder.com/48',
        poster: 'https://via.placeholder.com/600x400',
    },
];


export function BlogPageOne() {
    const postsPerPage = 9;
    const [selectedCategory, setSelectedCategory] = useState('All');
    const [currentPage, setCurrentPage] = useState(1);

    // Filter posts based on selected category
    const filteredPosts = selectedCategory === 'All'
        ? posts
        : posts.filter(post => post.category === selectedCategory);

    // Pagination logic
    const startIndex = (currentPage - 1) * postsPerPage;
    const paginatedPosts = filteredPosts.slice(startIndex, startIndex + postsPerPage);
    const totalPages = Math.ceil(filteredPosts.length / postsPerPage);

    const handleNext = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePrevious = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    return (
        <>
            <Helmet>
                <title>Blogs - Masterwiz Technology | IT Trends & Insights</title>
                <meta name="description" content="Stay updated with the latest trends in IT, software development, and digital marketing. Read insightful blogs from the Masterwiz Technology team." />
                <meta name="keywords" content="IT blogs, software development insights, digital marketing trends, IT solutions, Masterwiz Technology blogs" />
                <link rel="canonical" href="https://www.masterwiztechnologies.com/blogs" />
            </Helmet>
            <NavBar />
            <div className="pt-36 bg-gradient-to-r from-gray-50 via-gray-100 to-gray-200">
                <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                    <div className="flex flex-col space-y-6 pb-8 px-4 md:space-y-8 md:pb-10 md:px-0 text-center">
                        <p className="text-xl font-semibold text-gray-500 uppercase tracking-wider md:text-2xl lg:text-3xl">
                            Blog
                        </p>
                        <p className="text-3xl font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-blue-900 via-purple-900 to-blue-900 md:text-5xl lg:text-6xl">
                            Tech Insights & Latest Trends
                        </p>
                        <p className="max-w-2xl mx-auto text-base text-gray-600 md:max-w-3xl md:text-lg lg:text-xl">
                            Dive into our curated articles to stay updated with the latest in technology, from AI advancements to software development best practices.
                        </p>
                    </div>


                    <div className="hidden md:flex md:flex-col md:mt-12 md:w-full md:space-y-4 md:px-4">
                        <div className="w-full flex flex-wrap justify-between border-b border-gray-300">
                            {['All', 'AI & Machine Learning', 'Development', 'Cybersecurity', 'Cloud Computing', 'Blockchain', 'Marketing Technology'].map(
                                (filter) => (
                                    <div
                                        onClick={() => {
                                            setSelectedCategory(filter);
                                            setCurrentPage(1); // Reset to first page when category changes
                                            window.scrollTo(0, 0); // Scroll to top
                                        }}
                                        className={`cursor-pointer px-6 py-3 text-base font-semibold transition-colors duration-300 ${selectedCategory === filter
                                            ? 'text-indigo-600 border-b-2 border-indigo-600'
                                            : 'text-gray-700 hover:text-indigo-600 hover:border-b-2 hover:border-indigo-600'
                                            }`}
                                        key={filter}
                                    >
                                        {filter}
                                    </div>
                                )
                            )}
                        </div>
                    </div>

                    {/* posts */}
                    <div className="grid gap-8 gap-y-12 py-8 md:grid-cols-2 lg:grid-cols-3">
                        {paginatedPosts.map((post) => (
                            <div key={post.title} className="border rounded-lg shadow-sm hover:shadow-md transition-shadow duration-300">
                                <img src={post.poster} className="aspect-video w-full rounded-t-lg" alt={post.title} />
                                <div className="p-5">
                                    <p className="mt-2 text-xs font-semibold text-indigo-600 uppercase">
                                        #{post.category}
                                    </p>
                                    <p className="mt-4 text-xl font-bold text-gray-900">{post.title}</p>
                                    <p className="mt-4 text-sm text-gray-600">
                                        {post.description}
                                    </p>
                                    <div className="mt-6 flex items-center space-x-4">
                                        <img className="h-12 w-12 rounded-full" src={post.avatar} alt={post.author} />
                                        <div>
                                            <p className="text-sm font-semibold text-gray-900">{post.author}</p>
                                            <p className="text-sm text-gray-600">{post.date}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="w-full border-t border-gray-300 pt-6 mt-6">
                        <div className="flex items-center justify-between">
                            <div className="hidden md:block text-gray-700">
                                <p>
                                    Showing <strong>{startIndex + 1}</strong> to <strong>{Math.min(startIndex + postsPerPage, filteredPosts.length)}</strong> of <strong>{filteredPosts.length}</strong> articles
                                </p>
                            </div>
                            <div className="flex items-center space-x-2">
                                <button
                                    type="button"
                                    className="rounded-md bg-white px-5 py-3 text-base font-semibold text-gray-900 border border-gray-300 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2"
                                    onClick={() => {
                                        handlePrevious();
                                        window.scrollTo(0, 0); // Scroll to top
                                    }}
                                    disabled={currentPage === 1}
                                >
                                    Previous
                                </button>
                                <button
                                    type="button"
                                    className="rounded-md bg-indigo-600 px-5 py-3 text-base font-semibold text-white shadow-md hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2"
                                    onClick={() => {
                                        handleNext();
                                        window.scrollTo(0, 0); // Scroll to top
                                    }}
                                    disabled={currentPage === totalPages}
                                >
                                    Next
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Cta />
            <Footer />
        </>

    )
}

export default BlogPageOne;
