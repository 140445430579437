import React from 'react';
import img from '../images/web.svg';
import img2 from '../images/app.svg';
import img3 from '../images/hosting.svg';
import img4 from '../images/consultation.svg';

const Services = () => {

    return (
        <div id="services" className="bg-gray-100 py-12" >
            <section data-aos="zoom-in-down">
                <div className="my-4 py-4">
                    <h2 className="my-2 text-center text-3xl text-blue-900 uppercase font-bold">services</h2>

                    <div className='flex justify-center'>
                        <div className='w-24 border-b-4 border-blue-900'></div>
                    </div>
                    <h2 className="mt-4 mx-12 text-center text-xl lg:text-2xl font-semibold text-blue-900">We are deeply committed to the growth and success of our clients.</h2>
                </div>

                <div className="px-12" data-aos="fade-down" data-aos-delay="600">
                    <div className="grid sm:grid-cols-2 lg:grid-cols-4 gap-5">

                        <div className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                            <div className="m-2 text-justify text-sm">
                                <img alt="Masterwiz Technologies Web Development" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out" src={img} />
                                <h2 className="font-semibold my-4 text-2xl text-center">Web Development</h2>
                                <p className="text-md font-medium">
                                    We specialize in crafting bespoke websites tailored to your business needs. Our services include creating responsive, user-friendly sites that perform optimally across all devices, ensuring a seamless experience for your visitors.
                                </p>
                            </div>
                        </div>


                        <div className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                            <div className="m-2 text-justify text-sm">
                                <img alt="Masterwiz Technologies Mobile App Development" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out" src={img2} />
                                <h2 className="font-semibold my-4 text-2xl text-center">Mobile App Development</h2>
                                <p className="text-md font-medium">
                                    We design and develop custom mobile applications that deliver exceptional user experiences. Our apps are built for both iOS and Android platforms, ensuring high performance, scalability, and a smooth user interface.
                                </p>
                            </div>
                        </div>


                        <div className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                            <div className="m-2 text-justify text-sm">
                                <img alt="Masterwiz Technologies Domain and Hosting Services" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out" src={img3} />
                                <h2 className="font-semibold my-4 text-2xl text-center">Domain and Hosting Services</h2>
                                <p className="text-md font-medium">
                                    We offer comprehensive domain registration and web hosting solutions to ensure your online presence is robust and reliable. Our services provide the foundation for your website's success, including fast load times and high uptime.
                                </p>
                            </div>
                        </div>


                        {/* <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                                <div className="m-2 text-justify text-sm">
                                <img alt="card img" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out" src={img4} />
                                    <h2 className="font-semibold my-4 text-2xl text-center ">General IT Consultations</h2>
                                    <p className="text-md font-medium">
                                        Our IT consultations service provides expert guidance and support for businesses and individuals looking to optimize their technology solutions.
                                    </p>
                                </div>
                            </div>   */}
                        <div className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                            <div className="m-2 text-justify text-sm">
                                <img alt="Masterwiz Technologies Digital Marketing & Analytics Solution" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out" src={img4} />
                                <h2 className="font-semibold my-4 text-2xl text-center">Digital Marketing & Analytics Solutions</h2>
                                <p className="text-md font-medium">
                                    Our Digital Marketing & Analytics Solutions offer comprehensive strategies to enhance your online presence and drive measurable results. We provide data-driven insights and innovative marketing techniques to help businesses grow and succeed in the digital landscape.
                                </p>
                            </div>
                        </div>

                    </div>
                </div>
            </section>

            <section className="py-12 bg-gray-100">
                <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
                    <div className="flex flex-col lg:flex-row lg:space-x-8 py-8">
                        {/* Build Section */}
                        <div className="lg:w-1/2 flex flex-col justify-center mb-8 lg:mb-0">
                            <div className="flex items-center mb-4 text-blue-900">
                                <svg xmlns="http://www.w3.org/2000/svg" width="72" height="72" viewBox="0 0 24 24" className="fill-current">
                                    <path d="M2 12h2a7.986 7.986 0 0 1 2.337-5.663 7.91 7.91 0 0 1 2.542-1.71 8.12 8.12 0 0 1 6.13-.041A2.488 2.488 0 0 0 17.5 7C18.886 7 20 5.886 20 4.5S18.886 2 17.5 2c-.689 0-1.312.276-1.763.725-2.431-.973-5.223-.958-7.635.059a9.928 9.928 0 0 0-3.18 2.139 9.92 9.92 0 0 0-2.14 3.179A10.005 10.005 0 0 0 2 12zm17.373 3.122c-.401.952-.977 1.808-1.71 2.541s-1.589 1.309-2.542 1.71a8.12 8.12 0 0 1-6.13.041A2.488 2.488 0 0 0 6.5 17C5.114 17 4 18.114 4 19.5S5.114 22 6.5 22c.689 0 1.312-.276 1.763-.725A9.965 9.965 0 0 0 12 22a9.983 9.983 0 0 0 9.217-6.102A9.992 9.992 0 0 0 22 12h-2a7.993 7.993 0 0 1-.627 3.122z"></path>
                                    <path d="M12 7.462c-2.502 0-4.538 2.036-4.538 4.538S9.498 16.538 12 16.538s4.538-2.036 4.538-4.538S14.502 7.462 12 7.462zm0 7.076c-1.399 0-2.538-1.139-2.538-2.538S10.601 9.462 12 9.462s2.538 1.139 2.538 2.538-1.139 2.538-2.538 2.538z"></path>
                                </svg>
                            </div>
                            <h3 className="text-3xl text-blue-900 font-bold mb-4">
                                We <span className="font-black">Build</span>
                            </h3>
                            <p className="text-xl text-gray-700 font-semibold leading-relaxed">
                                With over 10 years of experience in software analysis and design, we provide tailored recommendations and strategies. Our focus is on improving operations, reducing costs, and enhancing efficiency with the latest IT trends and solutions.
                            </p>
                        </div>

                        {/* Collaborate Section */}
                        <div className="lg:w-1/2 flex flex-col justify-center">
                            <div className="flex items-center mb-4 text-blue-900">
                                <svg xmlns="http://www.w3.org/2000/svg" width="72" height="72" viewBox="0 0 24 24" className="fill-current">
                                    <path d="m7.375 16.781 1.25-1.562L4.601 12l4.024-3.219-1.25-1.562-5 4a1 1 0 0 0 0 1.562l5 4zm9.25-9.562-1.25 1.562L19.399 12l-4.024 3.219 1.25 1.562 5-4a1 1 0 0 0 0-1.562l-5-4zm-1.649-4.003-4 18-1.953-.434 4-18z"></path>
                                </svg>
                            </div>
                            <h3 className="text-3xl text-blue-900 font-bold mb-4">
                                We <span className="font-black">Collaborate</span>
                            </h3>
                            <p className="text-xl text-gray-700 font-semibold leading-relaxed">
                                We work closely with your tech team to scale existing applications or design custom software that meets your needs. Our goal is to simplify processes and enhance your operations with innovative solutions.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

        </div>
    )
}

export default Services;