import React, { useEffect } from 'react';
import { HashLink } from 'react-router-hash-link';
import { useLocation } from 'react-router-dom';

const NavLinks = () => {
    const location = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname]);
    const getLinkStyle = (path) => {
        return location.pathname === path
            ? 'px-4 font-extrabold text-blue-900 border-b-2 border-blue-900 w-min mx-auto transition duration-300'
            : 'px-4 font-extrabold text-gray-500 hover:text-blue-900 hover:border-b-2 hover:border-blue-900 w-min mx-auto transition duration-300';
    };

    return (
        <>
            <HashLink className={getLinkStyle('/')} to="/">
                Home
            </HashLink>
            <HashLink className={getLinkStyle('/about')} smooth to="/about">
                About
            </HashLink>
            <HashLink className={getLinkStyle('/services')} smooth to="/services">
                Services
            </HashLink>
            <HashLink className={getLinkStyle('/blogs')} smooth to="/blogs">
                Blog
            </HashLink>
            <HashLink className={getLinkStyle('/careers')} smooth to="/careers">
                Careers
            </HashLink>
            <HashLink className={getLinkStyle('/contact')} to="/contact">
                Contact Us
            </HashLink>
            <HashLink className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-auto px-6 py-3 shadow-xl rounded-xl" smooth to="/get-demo#demo">
                Demo our products
            </HashLink>
        </>
    );
};

export default NavLinks;
