import React from "react";
import { Link } from 'react-router-dom';
import Cta from "../Cta";

const Technology = () => {
    return (
        <div className="section py-12 bg-gray-100">
            <div className="container mx-auto">
                <div className="section-heading mb-12 text-center">
                    <h2 className="text-4xl font-bold text-gray-800">Technology</h2>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                    <div
                        className="services-grid bg-white p-6 rounded-lg shadow-lg hover:shadow-2xl transform hover:scale-105 transition-transform duration-300"
                        data-sal="slide-up"
                        data-sal-duration="800"
                        data-sal-delay="100"
                        style={{ maxWidth: "350px", margin: "0 auto" }}
                    >
                        <div className="thumbnail mb-4 text-6xl text-indigo-500">
                            💻
                        </div>
                        <div className="content">
                            <h5 className="text-2xl font-semibold text-gray-800 mb-2">
                                Hosting
                            </h5>
                            <p className="mb-4">
                                We offer reliable cloud hosting with 99.9% uptime, ensuring your applications are always online and performing at their best. Our services include managed backups, robust security, and scalable resources to meet your growing needs.
                            </p>
                            <ul className="list-disc list-inside">
                                <li>99.9% Uptime</li>
                                <li>Managed Backups</li>
                                <li>Scalable Resources</li>
                            </ul>
                        </div>
                    </div>

                    <div
                        className="services-grid bg-white p-6 rounded-lg shadow-lg hover:shadow-2xl transform hover:scale-105 transition-transform duration-300"
                        data-sal="slide-up"
                        data-sal-duration="800"
                        data-sal-delay="200"
                        style={{ maxWidth: "350px", margin: "0 auto" }}
                    >
                        <div className="thumbnail mb-4 text-6xl text-green-500">
                            💳
                        </div>
                        <div className="content">
                            <h5 className="text-2xl font-semibold text-gray-800 mb-2">
                                Payment Solutions
                            </h5>
                            <p className="mb-4">
                                Our payment solutions are designed to integrate seamlessly with your applications, supporting multiple payment methods including credit/debit cards, cryptocurrencies, and digital wallets. We ensure secure transactions with PCI-DSS compliance and fraud detection features.
                            </p>
                            <ul className="list-disc list-inside">
                                <li>Multi-currency Support</li>
                                <li>PCI-DSS Compliance</li>
                                <li>Fraud Detection</li>
                            </ul>
                        </div>
                    </div>

                    <div
                        className="services-grid bg-white p-6 rounded-lg shadow-lg hover:shadow-2xl transform hover:scale-105 transition-transform duration-300"
                        data-sal="slide-up"
                        data-sal-duration="800"
                        data-sal-delay="300"
                        style={{ maxWidth: "350px", margin: "0 auto" }}
                    >
                        <div className="thumbnail mb-4 text-6xl text-blue-500">
                            ☁️
                        </div>
                        <div className="content">
                            <h5 className="text-2xl font-semibold text-gray-800 mb-2">
                                Cloud Integration
                            </h5>
                            <p className="mb-4">
                                Leverage the power of cloud computing with our integration services. We help you connect your applications with leading cloud platforms like AWS, Azure, and Google Cloud, enabling seamless data storage, automated scaling, and enhanced performance.
                            </p>
                            <ul className="list-disc list-inside">
                                <li>AWS, Azure, and Google Cloud Integration</li>
                                <li>Automated Scaling</li>
                                <li>Enhanced Performance</li>
                            </ul>
                        </div>
                    </div>
                </div>
            
            </div>
        </div>
    );
};

export default Technology;
