import React from 'react';
import process1 from '../../images/process-1.png';
import process2 from '../../images/process-2.png';
import process3 from '../../images/process-3.png';
import process4 from '../../images/process-4.png';
import process5 from '../../images/process-3.png';
import process6 from '../../images/process-1.png';
// import Footer from '../Footer';
import { Link } from 'react-router-dom';

const WorkProcessArea = () => (
    <>
        <section className="bg-white py-12">
            <div className="container mx-auto px-4">
                <div className="text-center mb-10">
                    <span className="text-xl font-semibold text-red-600 ">PROCESS</span>
                    <br />
                    <span className="text-sm font-semibold text-gray-600">Our Design Process 🚀</span>
                    <h2 className="text-2xl md:text-3xl font-bold mt-4 mb-6">Our comprehensive design strategy ensures a perfectly crafted product for your business.</h2>
                </div>
                <div className="flex flex-wrap -mx-4 text-center">
                    <div className="w-full md:w-1/2 p-4 flex items-center justify-center bg-gray-100 order-2 md:order-1">
                        <div className="p-6">
                            <h3 className="text-2xl font-bold mb-4 text-blue-600">Step One: Idea 🔍</h3>
                            <p>We start by understanding your initial ideas and vision for your startup. Our team helps to refine these ideas and match them with the right tech talent through an efficient automated process, ensuring that your vision is brought to life with precision.</p>
                        </div>
                    </div>
                    <div className="w-full md:w-1/2 p-4 flex items-center justify-center bg-white order-1 md:order-2">
                        <img src={process1} alt="Step One: Idea" className="max-w-full h-auto" />
                    </div>

                    <div className="w-full md:w-1/2 p-4 flex items-center justify-center bg-white order-3">
                        <img src={process2} alt="Step Two: Design" className="max-w-full h-auto" />
                    </div>
                    <div className="w-full md:w-1/2 p-4 flex items-center justify-center bg-gray-100 order-4">
                        <div className="p-6">
                            <h3 className="text-2xl font-bold mb-4 text-green-600">Step Two: Design 🎨</h3>
                            <p>Our design sprint involves a thorough process where ideas are tested with potential users to address market challenges. We utilize the Brindocorp platform to efficiently plan, ideate, prototype, and test designs, ensuring that we gather valuable feedback and refine the product accordingly.</p>
                        </div>
                    </div>

                    <div className="w-full md:w-1/2 p-4 flex items-center justify-center bg-gray-100 order-6 md:order-5">
                        <div className="p-6">
                            <h3 className="text-2xl font-bold mb-4 text-purple-600">Step Three: Development 💻</h3>
                            <p>Throughout the development process, owners stay engaged as we bring the design to life. Our platform facilitates effective collaboration between frontend, backend, product design, and management teams, ensuring that the product is developed according to the design and is ready for deployment.</p>
                        </div>
                    </div>
                    <div className="w-full md:w-1/2 p-4 flex items-center justify-center bg-white order-5 md:order-6">
                        <img src={process3} alt="Step Three: Development" className="max-w-full h-auto" />
                    </div>

                    <div className="w-full md:w-1/2 p-4 flex items-center justify-center bg-white order-7">
                        <img src={process4} alt="Step Four: Testing" className="max-w-full h-auto" />
                    </div>
                    <div className="w-full md:w-1/2 p-4 flex items-center justify-center bg-gray-100 order-8">
                        <div className="p-6">
                            <h3 className="text-2xl font-bold mb-4 text-orange-600">Step Four: Testing 🧪</h3>
                            <p>We conduct comprehensive testing to ensure that your product performs flawlessly across all devices and browsers. Our testing phase includes functional, usability, and performance testing to identify and address any issues before launch.</p>
                        </div>
                    </div>

                    <div className="w-full md:w-1/2 p-4 flex items-center justify-center bg-gray-100 order-10 md:order-9">
                        <div className="p-6">
                            <h3 className="text-2xl font-bold mb-4 text-red-600">Step Five: Deployment 🚀</h3>
                            <p>We handle all aspects of deployment, ensuring that your product is smoothly transitioned to a live environment. Our team creates efficient workflows for product updates and integrates with cloud services like AWS to ensure reliable and scalable deployment.</p>
                        </div>
                    </div>
                    <div className="w-full md:w-1/2 p-4 flex items-center justify-center bg-white order-9 md:order-10">
                        <img src={process5} alt="Step Five: Deployment" className="max-w-full h-auto" />
                    </div>

                    <div className="w-full md:w-1/2 p-4 flex items-center justify-center bg-white order-11">
                        <img src={process6} alt="Step Six: Support" className="max-w-full h-auto" />
                    </div>
                    <div className="w-full md:w-1/2 p-4 flex items-center justify-center bg-gray-100 order-12">
                        <div className="p-6">
                            <h3 className="text-2xl font-bold mb-4 text-teal-600">Step Six: Support 🛠️</h3>
                            <p>Post-launch, we provide ongoing support to address any issues and ensure your product remains up-to-date and effective. Our support includes troubleshooting, updates, and enhancements to keep your product aligned with your evolving needs.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/* <Footer /> */}
    </>
);

export default WorkProcessArea;
