import React from "react";

const Development = () => {
    return (
        <div className="section py-12 bg-gray-100 ">
            <div className="container mx-auto">
                <div className="section-heading mb-12 text-center">
                    {/* <span className="text-sm text-red-500 uppercase tracking-wider"> Service</span> */}
                    <span className="text-md text-red-500 uppercase tracking-wider"> Service</span>
                    <h2 className="text-4xl font-bold text-gray-800">Development</h2>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                    <div
                        className="services-grid bg-white p-6 rounded-lg shadow-lg hover:shadow-2xl transform hover:scale-105 transition-transform duration-300"
                        data-sal="slide-up"
                        data-sal-duration="800"
                        data-sal-delay="100"
                        style={{ maxWidth: "350px", margin: "0 auto" }}
                    >
                        <div className="thumbnail mb-4 text-6xl text-indigo-500">
                            🖥️
                        </div>
                        <div className="content">
                            <h5 className="text-2xl font-semibold text-gray-800 mb-2">
                                Content Management Systems
                            </h5>
                            <p className="mb-4">
                                We specialize in designing and implementing customized Content Management Systems (CMS) tailored to your specific needs. Our solutions enhance the management and automation of content processes for businesses and organizations, ensuring efficient and streamlined operations.
                            </p>
                        </div>
                    </div>

                    <div
                        className="services-grid bg-white p-6 rounded-lg shadow-lg hover:shadow-2xl transform hover:scale-105 transition-transform duration-300"
                        data-sal="slide-up"
                        data-sal-duration="800"
                        data-sal-delay="200"
                        style={{ maxWidth: "350px", margin: "0 auto" }}
                    >
                        <div className="thumbnail mb-4 text-6xl text-green-500">
                            📱
                        </div>
                        <div className="content">
                            <h5 className="text-2xl font-semibold text-gray-800 mb-2">
                                Mobile App Development
                            </h5>
                            <p className="mb-4">
                                We craft professional mobile applications designed to provide a seamless user experience across various platforms. Our applications are compatible with both Android and iOS devices, ensuring a wide reach and high functionality for your business or project.
                            </p>
                        </div>
                    </div>

                    <div
                        className="services-grid bg-white p-6 rounded-lg shadow-lg hover:shadow-2xl transform hover:scale-105 transition-transform duration-300"
                        data-sal="slide-up"
                        data-sal-duration="800"
                        data-sal-delay="300"
                        style={{ maxWidth: "350px", margin: "0 auto" }}
                    >
                        <div className="thumbnail mb-4 text-6xl text-pink-500">
                            🛒
                        </div>
                        <div className="content">
                            <h5 className="text-2xl font-semibold text-gray-800 mb-2">
                                eCommerce Solutions
                            </h5>
                            <p className="mb-4">
                                We design and develop comprehensive eCommerce solutions that connect you with your customers through effective online platforms. Our services include the creation, customization, and deployment of eCommerce systems that enhance your business's online presence and drive sales.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Development;
