import React, { useState, useEffect } from 'react';
import NavLinks from '../Navbar/NavLinks';
import { HashLink } from 'react-router-hash-link';
import logo from '../../images/logo.png'
const ContactNavBar = () => {
    return (

        <div className="bg-blue-900 text-white py-1 fixed w-full top-0 z-50 shadow-lg hidden lg:block">
            <div className="container mx-auto flex justify-center items-center px-4 overflow-x-auto">
                <div className="text-sm text-center md:text-left flex space-x-8 min-w-max">
                    <span className="mr-8 border-r border-white pr-8 flex items-center hover:text-blue-300 transition-colors duration-300">
                        <a href="https://mail.google.com/mail/?view=cm&to= masterwiztechno@gmail.com" className="flex items-center" target="_blank" rel="noopener noreferrer">
                            <span role="img" aria-label="email" className="mr-2">📧</span>  masterwiztechno@gmail.com
                        </a>
                    </span>

                    <span className="mr-8 border-r border-white pr-8 flex items-center hover:text-blue-300 transition-colors duration-300">
                        <a href="https://wa.me/919151777311" className="flex items-center" target="_blank" rel="noopener noreferrer">
                            <span role="img" aria-label="phone" className="mr-2">📞</span> +919151777311
                        </a>
                    </span>
                    <span className="flex items-center hover:text-blue-300 transition-colors duration-300">
                        <a href="skype:masterwiztechnologies?chat" className="flex items-center">
                            <span role="img" aria-label="skype" className="mr-2">💬</span> masterwiztechnologies
                        </a>
                    </span>
                </div>
            </div>
        </div>




    );
};
const NavBar = () => {
    const [top, setTop] = useState(!window.scrollY);
    const [isOpen, setisOpen] = React.useState(false);
    function handleClick() {
        setisOpen(!isOpen);
    }


    useEffect(() => {
        const scrollHandler = () => {
            window.pageYOffset > 10 ? setTop(false) : setTop(true)
        };
        window.addEventListener('scroll', scrollHandler);
        return () => window.removeEventListener('scroll', scrollHandler);
    }, [top]);

    return (
        <>
        <h1 class="sr-only">Best IT Solutions Provider in Varanasi</h1>
            <ContactNavBar />
            <nav className={`fixed top-0 md:top-5 w-full h-14 md:h-16 lg:h-[11.5vh] xl:h-[11.5vh] 2xl:h-[12.5vh] z-30 transition duration-300 ease-in-out mb-16 ${!top ? 'bg-white' : 'bg-transparent'}`}>
                <div className="flex flex-row justify-between items-center py-2">
                    <div className="flex flex-row justify-center md:px-12 md:mx-12 items-center text-center font-semibold">
                        <HashLink smooth to="/#hero">
                            <img
                                src={logo}
                                alt="Masterwiz Technology Logo"
                                className="h-10 w-auto sm:h-10 md:h-12 lg:h-16 xl:h-16"
                            />
                        </HashLink>

                    </div>
                    <div className="group flex flex-col items-center">
                        <button className="p-2 rounded-lg lg:hidden text-blue-900" onClick={handleClick}>
                            <svg className="h-6 w-6 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                {isOpen && (
                                    <path fillRule="evenodd" clipRule="evenodd" d="M18.278 16.864a1 1 0 0 1-1.414 1.414l-4.829-4.828-4.828 4.828a1 1 0 0 1-1.414-1.414l4.828-4.829-4.828-4.828a1 1 0 0 1 1.414-1.414l4.829 4.828 4.828-4.828a1 1 0 1 1 1.414 1.414l-4.828 4.829 4.828 4.828z" />
                                )}
                                {!isOpen && (
                                    <path fillRule="evenodd" d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z" />
                                )}
                            </svg>
                        </button>
                        <div className='hidden space-x-6 lg:inline-block p-5'>
                            <NavLinks />
                        </div>

                        <div className={`fixed transition-transform duration-300 ease-in-out transit flex justify-center left-0 w-full h-auto rounded-md p-14 bg-white lg:hidden shadow-xl top-12 ${isOpen ? "block" : "hidden"} `}>
                            <div className='flex flex-col space-y-6'>
                                <NavLinks />
                            </div>
                        </div>

                    </div>
                </div>
            </nav>
        </>
    )

}


export default NavBar;
