import React from 'react';
import { Link } from 'react-router-dom';

const Portfolio = () => {
    return (
        <>
            <div className="my-8 py-8" id='portfolio'>
                <h2 className="my-4 text-center text-4xl text-blue-900 uppercase font-bold">Our Portfolio</h2>
                <div className='flex justify-center'>
                    <div className='w-32 border-b-4 border-blue-900 mb-12'></div>
                </div>
                <h4 className="my-4 text-center text-3xl text-blue-900 uppercase font-semibold">Previous Projects</h4>

                <div className="px-6" data-aos="fade-up" data-aos-delay="500">
                    <div className="grid sm:grid-cols-2 lg:grid-cols-4 gap-8">
                        <div className="bg-white transition-transform ease-in-out duration-500 hover:scale-105 overflow-hidden text-gray-800 rounded-lg shadow-lg p-4 min-h-max">
                            <div className="m-4 text-justify text-base">
                                <h4 className="font-bold my-4 text-xl md:text-2xl text-center mb-4 h-16">MyMaster11</h4>
                                <p className="text-base font-medium leading-6 h-auto md:h-48">
                                    MyMaster11 is an engaging fantasy sports application that allows users to create and manage fantasy teams across various sports including cricket, kabaddi, football, basketball, volleyball, and hockey. It offers a thrilling experience for sports enthusiasts.
                                </p>
                                <div className="flex justify-center my-4">
                                    <Link to="/get-demo" className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-3 text-lg shadow-lg rounded-xl">
                                        Schedule a Demo
                                        <svg className="w-5 h-5 ml-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                            <path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path>
                                        </svg>
                                    </Link>
                                </div>
                            </div>
                        </div>

                        <div className="bg-white transition-transform ease-in-out duration-500 hover:scale-105 overflow-hidden text-gray-800 rounded-lg shadow-lg p-4">
                            <div className="m-4 text-justify text-base">
                                <h4 className="font-bold my-4 text-xl md:text-2xl text-center mb-4 h-16">Narayanam</h4>
                                <p className="text-base font-medium leading-6 h-auto md:h-48">
                                    Narayanam is an e-commerce platform dedicated to worship products. It offers a wide range of idols and priest services, catering to all your spiritual needs with convenience and quality.
                                </p>
                                <div className="flex justify-center my-4">
                                    <Link to="/get-demo" className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-3 text-lg shadow-lg rounded-xl">
                                        Schedule a Demo
                                        <svg className="w-5 h-5 ml-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                            <path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path>
                                        </svg>
                                    </Link>
                                </div>
                            </div>
                        </div>

                        <div className="bg-white transition-transform ease-in-out duration-500 hover:scale-105 overflow-hidden text-gray-800 rounded-lg shadow-lg p-4">
                            <div className="m-4 text-justify text-base">
                                <h4 className="font-bold my-4 text-xl md:text-2xl text-center mb-4 h-16">Opinion Trading</h4>
                                <p className="text-base font-medium leading-6 h-auto md:h-48">
                                    Opinion is a dynamic platform where users can engage in trading based on diverse opinions and predictions. Users have the opportunity to provide and consider opinions specifically related to sports, fostering a unique and interactive trading environment.
                                </p>
                                <div className="flex justify-center my-4">
                                    <Link to="/get-demo" className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-3 text-lg shadow-lg rounded-xl">
                                        Schedule a Demo
                                        <svg className="w-5 h-5 ml-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                            <path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path>
                                        </svg>
                                    </Link>
                                </div>
                            </div>
                        </div>

                        <div className="bg-white transition-transform ease-in-out duration-500 hover:scale-105 overflow-hidden text-gray-800 rounded-lg shadow-lg p-4">
                            <div className="m-4 text-justify text-base">
                                <h4 className="font-bold my-4 text-xl md:text-2xl text-center mb-4 h-16">Sportswiz</h4>
                                <p className="text-base font-medium leading-6 h-auto md:h-48">
                                    Sportswiz provides a comprehensive sports data API that delivers real-time sports scores and information. It is designed for developers and sports enthusiasts who need accurate and up-to-date sports data.
                                </p>
                                <div className="flex justify-center my-4">
                                    <Link to="/get-demo" className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-3 text-lg shadow-lg rounded-xl">
                                        Schedule a Demo
                                        <svg className="w-5 h-5 ml-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                            <path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path>
                                        </svg>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Portfolio;
