import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css'; // Ensure this path is correct for your Swiper version
import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import kws from '../images/clients/kws.png';
import geps from '../images/clients/geps.png';
import ludo from '../images/clients/ludo.png';
import hint from '../images/clients/hint.png';
import shawty from '../images/clients/SHAWTY.png';
import opinion from '../images/clients/opinio.png';
import protergia from '../images/clients/protergia.png';


const clientImage = {
    height: '8rem', // Reduced height for mobile
    width: 'auto',
    mixBlendMode: 'colorBurn',
};

const Clients = () => {
    return (
        <div className="mt-4 bg-white-100 md:mt-8">
            <section data-aos="fade-up">
                <div className="my-2 py-2 md:my-4 md:py-4">
                    <h2 className="my-2 text-center text-2xl md:text-3xl text-blue-900 uppercase font-bold">Our Clients</h2>
                    <div className='flex justify-center'>
                        <div className='w-16 border-b-4 border-blue-900 md:w-24'></div>
                    </div>
                    <h2 className="mt-4 mx-4 text-center text-lg md:text-xl lg:text-2xl font-semibold text-blue-900">
                        Trusted By Over 50+ Organizations Recommended Around The Nation
                    </h2>
                </div>

                <div className="px-4 py-8 md:p-16" data-aos="fade-in" data-aos-delay="600">
                    <Swiper
                        spaceBetween={20} // Reduced space for mobile
                        slidesPerView={1} // Default 1 slide per view for mobile
                        autoplay={{ delay: 800 }}
                        loop={true}
                        pagination={{ clickable: true }}
                        navigation
                        modules={[Autoplay, Navigation, Pagination]}
                        breakpoints={{
                            640: {
                                slidesPerView: 1,
                            },
                            768: {
                                slidesPerView: 2,
                                spaceBetween: 30,
                            },
                            1024: {
                                slidesPerView: 3,
                                spaceBetween: 30,
                            },
                            1280: {
                                slidesPerView: 4,
                                spaceBetween: 30,
                            },
                        }}
                    >
                        <SwiperSlide>
                            <div style={clientImage} className="overflow-hidden flex justify-center transition-all ease-in-out  ">
                                <img src={kws} alt="Masterwiz Technologies client" />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div style={clientImage} className="overflow-hidden flex justify-center transition-all ease-in-out ">
                                <img src={protergia} alt="Masterwiz Technologies client" />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div style={clientImage} className="overflow-hidden flex justify-center transition-all ease-in-out ">
                                <img src={geps} alt="Masterwiz Technologies client" />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div style={clientImage} className="overflow-hidden flex justify-center transition-all ease-in-out ">
                                <img src={shawty} alt="Masterwiz Technologies client" />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div style={clientImage} className="overflow-hidden flex justify-center transition-all ease-in-out ">
                                <img src={hint} alt="Masterwiz Technologies client" />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div style={{
                                ...clientImage,
                                width: '150px',      // Adjust the width as needed
                                height: '150px',     // Adjust the height as needed
                                borderRadius: '50%', // Makes the container circular
                                overflow: 'hidden',  // Ensures the image fits within the circle
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }} className="transition-all ease-in-out">
                                <img src={opinion} alt="Masterwiz Technologies client" style={{
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'cover'  // Ensures the image covers the container while maintaining aspect ratio
                                }} />
                            </div>
                        </SwiperSlide>

                        <SwiperSlide>
                            <div style={clientImage} className="overflow-hidden flex justify-center transition-all ease-in-out ">
                                <img src={ludo} alt="Masterwiz Technologies client" />
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </div>
            </section>
        </div>
    );
};

export default Clients;
