import React from "react";

const OnlineMarketing = () => {
  return (
    <div className="section py-12 bg-gray-100">
      <div className="container mx-auto">
        <div className="section-heading mb-12 text-center">
          <h2 className="text-4xl font-bold text-gray-800">Online Marketing</h2>
          <p className="text-lg text-gray-600 mt-4">
            {/* Explore our comprehensive online marketing services tailored to grow your business. */}
          </p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {/* SEO */}
          <div
            className="services-grid bg-white p-6 rounded-lg shadow-lg hover:shadow-2xl transform hover:scale-105 transition-transform duration-300"
            data-sal="slide-up"
            data-sal-duration="800"
            data-sal-delay="100"
            style={{ maxWidth: "350px", margin: "0 auto" }}
          >
            <div className="thumbnail mb-4 text-6xl text-indigo-500">🔍</div>
            <div className="content">
              <h5 className="text-2xl font-semibold text-gray-800 mb-2">SEO</h5>
              <p className="mb-4">
                Search Engine Optimization (SEO) is the foundation of any successful online presence. 
                We optimize your website content, structure, and performance to improve your rankings 
                on search engines like Google. This includes keyword research, on-page optimization, 
                backlink building, and continuous performance monitoring to ensure you stay ahead of your competitors.
              </p>
            </div>
          </div>

          {/* Social Media Marketing */}
          <div
            className="services-grid bg-white p-6 rounded-lg shadow-lg hover:shadow-2xl transform hover:scale-105 transition-transform duration-300"
            data-sal="slide-up"
            data-sal-duration="800"
            data-sal-delay="200"
            style={{ maxWidth: "350px", margin: "0 auto" }}
          >
            <div className="thumbnail mb-4 text-6xl text-green-500">📱</div>
            <div className="content">
              <h5 className="text-2xl font-semibold text-gray-800 mb-2">Social Media Marketing</h5>
              <p className="mb-4">
                Social Media Marketing (SMM) is vital for building a strong online community around your brand. 
                We manage your social media profiles on platforms like Facebook, Instagram, Twitter, and LinkedIn, 
                creating engaging content and running targeted ad campaigns that resonate with your audience. 
                Our goal is to increase your brand awareness, drive website traffic, and ultimately boost sales.
              </p>
            </div>
          </div>

          {/* Display Marketing */}
          <div
            className="services-grid bg-white p-6 rounded-lg shadow-lg hover:shadow-2xl transform hover:scale-105 transition-transform duration-300"
            data-sal="slide-up"
            data-sal-duration="800"
            data-sal-delay="300"
            style={{ maxWidth: "350px", margin: "0 auto" }}
          >
            <div className="thumbnail mb-4 text-6xl text-green-500">🖼️</div>
            <div className="content">
              <h5 className="text-2xl font-semibold text-gray-800 mb-2">Performance Marketing</h5>
              <p className="mb-4">
                Display Marketing involves placing visual ads on websites, social media, and other digital platforms 
                to attract potential customers. We design eye-catching banner ads, video ads, and interactive media 
                that capture attention and encourage clicks. Our display marketing strategies are tailored to reach 
                your target audience through precise demographic and behavioral targeting.
              </p>
            </div>
          </div>

          {/* Email Marketing */}
          <div
            className="services-grid bg-white p-6 rounded-lg shadow-lg hover:shadow-2xl transform hover:scale-105 transition-transform duration-300"
            data-sal="slide-up"
            data-sal-duration="800"
            data-sal-delay="400"
            style={{ maxWidth: "350px", margin: "0 auto" }}
          >
            <div className="thumbnail mb-4 text-6xl text-green-500">📧</div>
            <div className="content">
              <h5 className="text-2xl font-semibold text-gray-800 mb-2">Email Marketing</h5>
              <p className="mb-4">
                Email Marketing remains one of the most effective ways to nurture leads and convert them into customers. 
                We create and manage email campaigns that are personalized, timely, and relevant to your audience. 
                From welcome emails and newsletters to product announcements and special promotions, our campaigns 
                are designed to keep your brand top-of-mind and drive conversions.
              </p>
            </div>
          </div>

          {/* Pay-Per-Click (PPC) */}
          <div
            className="services-grid bg-white p-6 rounded-lg shadow-lg hover:shadow-2xl transform hover:scale-105 transition-transform duration-300"
            data-sal="slide-up"
            data-sal-duration="800"
            data-sal-delay="500"
            style={{ maxWidth: "350px", margin: "0 auto" }}
          >
            <div className="thumbnail mb-4 text-6xl text-pink-500">💰</div>
            <div className="content">
              <h5 className="text-2xl font-semibold text-gray-800 mb-2">Pay-Per-Click (PPC)</h5>
              <p className="mb-4">
                Pay-Per-Click (PPC) advertising is a cost-effective way to drive targeted traffic to your website. 
                We design and manage PPC campaigns on platforms like Google Ads and Bing Ads, ensuring your ads 
                appear when potential customers search for products or services like yours. We continuously monitor 
                and optimize your campaigns to maximize ROI, focusing on keyword bidding, ad copy, and landing page performance.
              </p>
            </div>
          </div>

          {/* Content Marketing */}
          <div
            className="services-grid bg-white p-6 rounded-lg shadow-lg hover:shadow-2xl transform hover:scale-105 transition-transform duration-300"
            data-sal="slide-up"
            data-sal-duration="800"
            data-sal-delay="600"
            style={{ maxWidth: "350px", margin: "0 auto" }}
          >
            <div className="thumbnail mb-4 text-6xl text-blue-500">✍️</div>
            <div className="content">
              <h5 className="text-2xl font-semibold text-gray-800 mb-2">Content Marketing</h5>
              <p className="mb-4">
                Content Marketing is all about creating valuable content that attracts and engages your target audience. 
                We develop a content strategy that aligns with your business goals, producing blog posts, articles, 
                videos, infographics, and more. Our content is designed to educate, inform, and entertain, building 
                trust with your audience and establishing your brand as an industry leader.
              </p>
            </div>
          </div>

          {/* Analytics & Reporting */}
          {/* <div
            className="services-grid bg-white p-6 rounded-lg shadow-lg hover:shadow-2xl transform hover:scale-105 transition-transform duration-300"
            data-sal="slide-up"
            data-sal-duration="800"
            data-sal-delay="700"
            style={{ maxWidth: "350px", margin: "0 auto" }}
          >
            <div className="thumbnail mb-4 text-6xl text-orange-500">📊</div>
            <div className="content">
              <h5 className="text-2xl font-semibold text-gray-800 mb-2">Analytics & Reporting</h5>
              <p className="mb-4">
                Analytics & Reporting are essential for understanding the effectiveness of your marketing efforts. 
                We provide detailed reports on website traffic, user behavior, conversion rates, and campaign performance. 
                Using tools like Google Analytics and custom dashboards, we track key metrics and provide actionable insights 
                to help you make data-driven decisions that enhance your marketing strategies.
              </p>
            </div>
          </div> */}
        </div>

        {/* Call to Action */}
        {/* <div className="cta-section mt-16 text-center">
          <h3 className="text-3xl font-bold text-gray-800">Ready to Boost Your Online Presence?</h3>
          <p className="text-lg text-gray-600 mt-4 mb-8">
            Contact us today to learn more about our services and how we can help your business grow.
          </p>
          <button className="px-6 py-3 bg-indigo-600 text-white font-semibold rounded-lg shadow-lg hover:bg-indigo-700 transition-colors">
            Get Started
          </button>
        </div> */}
      </div>
    </div>
  );
};

export default OnlineMarketing;
