// App.jsx
import React from 'react';
import BreadcrumbBanner from './BreadcrumbBanner';
import ServiceNav from './OnlineMarketing';
import ServiceSection from './ServiceSection';
import NavBar from '../Navbar/NavBar';
import Development from './Development';
import Footer from '../Footer';
import Technology from './Technology';
import Cta from '../Cta';
import Clients from '../Clients';
import { Helmet } from 'react-helmet';

const ServicePage = () => {
    return (
        <>
            <Helmet>
                <title>Our Services - Masterwiz Technology | Web Development & Digital Marketing</title>
                <meta name="description" content="Explore the range of services offered by Masterwiz Technology, including web development, mobile app development, and digital marketing for startups." />
                <meta name="keywords" content="services, web development, mobile app development, digital marketing, IT services for startups, Masterwiz Technology" />
                <link rel="canonical" href="https://www.masterwiztechnologies.com/services" />
            </Helmet>
            <div className="w-full">
                <NavBar />
            </div>
            <div className="w-full">
                <h1 class="sr-only">Custom Software Development in Varanasi</h1>
                <p class="sr-only">Masterwiz Technologies provides a range of services, including custom software development, mobile application development, e-commerce platforms, and digital marketing. Our expert team is based in Varanasi and serves businesses across India.</p>

                <BreadcrumbBanner />
                <Development />
                <ServiceNav />
                <ServiceSection />
                <Technology />
                <Clients />
                <Cta />
                <Footer />
            </div>
        </>
    );
};

export default ServicePage;
