import React, { useRef } from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import iphone from '../images/iphoneTransparent.png'
import service1 from '../images/service1.png'
import service2 from '../images/service2.png'
import service3 from '../images/service3.png'
import service4 from '../images/service4.png'
import vector from '../images/backgroundVector.png'
import contact from '../pages/Contact'
import { Link } from 'react-router-dom'



const Service = () => {


    const images = [
        {
            'id': 1,
            'url': service1,
        },
        {
            'id': 2,
            'url': service2,
            'bg': 'bg-[#F54D57]'
        },
        {
            'id': 3,
            'url': service3,
            "bg": 'bg-[#F9DE54]'
        },
        {
            'id': 4,
            'url': service4,
            "bg": 'bg-[#A45DBD]'
        }
    ]

    const [slide, setSlide] = useState(0);

    const timeoutRef = useRef(null);

    function resetTimeout() {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
    }

    useEffect(() => {
        resetTimeout();
        timeoutRef.current = setTimeout(
            () =>
                setSlide((prevIndex) =>
                    prevIndex === images.length - 1 ? 0 : prevIndex + 1
                ),
            3000
        );
        return () => {
            resetTimeout();
        };
    }, [slide]);


    return (


        <div className="w-full h-[90vh] relative py-10 md:py-5 lg:py-0" style={{ backgroundImage: `url(${vector})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>

            <div className=' w-full lg:w-[90%] xl:w-[70%] h-full flex-none  md:flex m-auto justify-between items-center py-5 lg:py-0'>
                <div className=' flex flex-col md:gap-5 justify-around lg:justify-center gap-5 sm:py-0 sm:h-full w-full'>
                    <h2 className='md:static top-8 left-14 md:mt-0 text-4xl md:text-[55px] lg:w-full font-extrabold text-center leading-tight lg:text-start text-blue-900'>
                        let us craft  it for you.
                    </h2>
                    <div className=' flex md:h-full lg:hidden flex-col md:gap-10 justify-center items-center w-full'>
                        <div className=' flex w-[200px]  h-[400px] lg:h-auto relative overflow-hidden'>
                            <Link to='services' className=' z-10 w-full h-full'>
                                <img src={iphone} alt='Masterwiz Technologies services'/>
                            </Link>
                            <div style={{ transform: `translate3d(${-slide * 100}%, 0, 0)` }} className=' h-full w-full whitespace-nowrap absolute transition ease-in-out duration-1000'>
                                {images.map((image) => (
                                    <img key={image.id} src={image.url} alt="Masterwiz Technologies services" className={` ${image.bg} w-full h-full inline-block object-contain`} />
                                ))}
                            </div>
                        </div>
                        <div className=' h-3 rounded-full w-32 mt-5  bg-gray-300 overflow-hidden'>
                            {images.map((image) => (
                                <div key={image.id} style={{ transform: `translate3d(${slide * 132}%, 0, 0)` }} className='h-full rounded-full w-[20%] bg-slate-500 transition ease-linear duration-1000'></div>
                            ))}
                        </div>
                    </div>

                    <div className='md:static md:flex-none xs:mt-16 flex flex-col justify-center gap-5 items-center bottom-5'>
                        {/* <div className='text-left lg:text-left'> */}
                        <p className='text-lg md:text-[26px] font-bold text-gray-600'>
                            delivering industry-leading solutions <br /> tailored to meet your needs and empower <br /> you to become the next big success.
                        </p>
                        <div className='flex justify-start'>
                            <Link to="/contact" className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-full sm:w-auto sm:mb-0 group">
                                Get Started
                                <svg className="w-5 h-5 ml-2 group-hover:translate-x-2 transition-transform" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                    <path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path>
                                </svg>
                            </Link>
                        </div>

                        {/* </div> */}
                    </div>
                </div>
                <div className='hidden md:static lg:flex lg:h-full flex-col md:gap-10  justify-center items-end w-full'>
                    <div className=' flex md:w-[150px] lg:w-[240px] xl:w-[280px] h-[400px] md:h-auto relative overflow-hidden'>
                        <Link to='services' className=' z-10 w-full h-full'>
                            <img src={iphone} alt='Masterwiz Technologies services'/>
                        </Link>
                        <div style={{ transform: `translate3d(${-slide * 100}%, 0, 0)` }} className=' h-full w-full whitespace-nowrap absolute transition ease-in-out duration-1000'>
                            {images.map((image) => (
                                <img src={image.url} alt="services" className={` ${image.bg} w-full h-full inline-block object-contain `} />
                            ))}
                        </div>
                    </div>
                    {/* <div className=' h-3 rounded-full w-[240px] lg:w-[180px]  xl:w-[231px] mt-5 md:mt-0 ml-8 md:ml-0 md:mr-8 bg-gray-300 overflow-hidden'>
                        {images.map((image) => (
                            <div style={{ transform: `translate3d(${slide * 118}%, 0, 0)` }} className='h-full rounded-full w-[53px] bg-slate-500 transition ease-linear duration-1000'></div>
                        ))}
                    </div> */}
                </div>
            </div>
        </div>
    )
}

export default Service