import React, { useState, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import img from '../images/Web-developer.svg';
import { Link } from 'react-router-dom';

const Intro = () => {
    const [startCount, setStartCount] = useState(false);
    const { ref, inView } = useInView({
        triggerOnce: true,
        threshold: 0.1,
    });

    useEffect(() => {
        if (inView) {
            setStartCount(true);
        }
    }, [inView]);

    const countUp = (target, end) => {
        let start = 0;
        const duration = 2000; // Duration in milliseconds
        const increment = end / (duration / 100);

        const interval = setInterval(() => {
            start += increment;
            if (start >= end) {
                clearInterval(interval);
                start = end;
            }
            target.innerHTML = Math.floor(start);
        }, 100);
    };

    useEffect(() => {
        if (startCount) {
            countUp(document.getElementById('clients'), 56);
            countUp(document.getElementById('projects'), 56);
            countUp(document.getElementById('nerds'), 19);
            countUp(document.getElementById('years'), 20);
        }
    }, [startCount]);
    return (
        <>
            <div className="bg-blue-900 text-white py-8" ref={ref}>
                <div className="max-w-6xl mx-auto grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4 text-center">
                    <div className="flex flex-col items-center">
                        <div className="text-4xl mb-2">😊</div>
                        <p id="clients" className="text-3xl font-bold">0</p>
                        <p className="text-sm">Happy Clients</p>
                    </div>
                    <div className="flex flex-col items-center">
                        <div className="text-4xl mb-2">💼</div>
                        <p id="projects" className="text-3xl font-bold">0</p>
                        <p className="text-sm">Live Projects</p>
                    </div>
                    <div className="flex flex-col items-center">
                        <div className="text-4xl mb-2">👨‍💻</div>
                        <p id="nerds" className="text-3xl font-bold">0</p>
                        <p className="text-sm">Services</p>
                    </div>
                    <div className="flex flex-col items-center">
                        <div className="text-4xl mb-2">🏆</div>
                        <p id="years" className="text-3xl font-bold">0</p>
                        <p className="text-sm">Years in Business</p>
                    </div>
                </div>
            </div>

            <div className="m-auto max-w-6xl p-2 md:p-12 h-5/6" id='about'>
                <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left" data-aos="fade-up">
                    <div className="lg:w-1/2 flex flex-col lg:mx-4 justify-center">
                        <img
                            alt="Masterwiz Technologies Card content"
                            className="rounded-t-lg shadow-lg w-full transition-transform duration-300 ease-in-out transform hover:scale-105 hover:rotate-1 hover:shadow-2xl hover:translate-y-[-5px]"
                            src={img}
                        />
                    </div>
                    <div className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500">
                        <h3 className="text-3xl font-extrabold text-blue-900 mb-4 relative">
                            <span className="absolute inset-x-0 bottom-0 h-1 bg-blue-900 transform scale-x-0 transition-transform duration-300 group-hover:scale-x-100"></span>
                            We build top-tier Web and Mobile apps, plus complete Marketing solutions
                        </h3>

                        <p className='my-3 text-l text-gray-700'>
                            Established in 2020, Masterwiz Technologies excels in custom software development using cutting-edge techniques.
                        </p>
                        <p className='my-3 text-l text-gray-700'>
                            Our expert team delivers tailored software solutions to enhance your business efficiency.
                        </p>
                        <p className='my-3 text-l text-gray-700'>
                            We as an IT and Marketing organization function the best way possible. We create cutting-edge products and services that offer complete comm & information solutions.
                        </p>
                        <Link to="/contact" className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-2xl sm:w-auto sm:mb-0 group">
                            Contact Us
                            <svg className="w-5 h-5 ml-2 group-hover:translate-x-2 transition-transform" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path>
                            </svg>
                        </Link>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Intro;
