import React from 'react';
import BreadcrumbArea from './BreadcrumbArea';
import AboutArea from './AboutArea';
import TeamArea from './TeamArea';
import WorkProcessArea from './WorkProcessArea';
import QualityArea from './QualityArea'
import NavBar from '../Navbar/NavBar';
import Footer from '../Footer';
import { Helmet } from 'react-helmet';
// import './App.css'; // Import your CSS file here
import Cta from '../Cta';
import Clients from '../Clients';

const About = () => {
    return (
        <>
            <Helmet>
                <title>About Us - Masterwiz Technology | IT Consulting & Software Experts</title>
                <meta name="description" content="Discover the mission and team behind Masterwiz Technology. We offer cutting-edge IT solutions and expert software development services for startups." />
                <meta name="keywords" content="about Masterwiz Technology, IT consulting, software experts, IT solutions, software development for startups" />
                <link rel="canonical" href="https://www.masterwiztechnologies.com/about" />
            </Helmet>
            <div className="w-full">
                <NavBar />
            </div>
            <h1 class="sr-only">Best IT Company in Varanasi</h1>
            <p class="sr-only">Learn more about Masterwiz Technologies, the top digital transformation company in Varanasi, Uttar Pradesh. We are known for our innovative solutions, experienced team, and customer-centric approach.</p>

            <div id="about" className="w-full">
                <div className="w-full">
                    <BreadcrumbArea />
                </div>
                <div className="w-full">
                    <AboutArea />
                </div>
                <div className="w-full">
                    <QualityArea />
                </div>
                <div className="w-full">
                    <TeamArea />
                </div>
                <div className="w-full">
                    <WorkProcessArea />
                </div>
                <Clients />
                <Cta />
                <Footer />
            </div>
        </>
    );
};

export default About;
