import React from 'react';
import bannerThumb from '../../images/case-study-4.png';

const AboutArea = () => (
  <section className="py-16 bg-gray-100">
    <div className="container mx-auto flex flex-wrap px-4">
      <div className="w-full md:w-7/12 mb-8 md:mb-0 relative">
        <img src={bannerThumb} alt="Masterwiz Technologies about" className="w-auto h-auto rounded-lg  transform hover:scale-105 transition-transform duration-300" />
      </div>
      <div className="w-full md:w-5/12 flex flex-col justify-center px-4">
        <span className="text-sm font-semibold text-red-700 uppercase tracking-wider">Who we are</span>
        <h2 className="text-xl md:text-5xl font-bold mt-4 mb-6 text-gray-800">Building digital products for world changers</h2>
        <p className="mb-4 text-gray-700 leading-relaxed">Masterwiz Technologies is not just a software company in Varanasi, India. We have some of the best digital marketers and product designers in Varanasi. Our developers are passionate about the success of businesses. Therefore, you can count on us when you need the right website, mobile app, or software for your business and organization.</p>
        <p className="mb-6 text-gray-700 leading-relaxed">While many software companies would take off after building a website, we remain with you. This is because we are always committed to you even after completing your product.</p>
        <div className="flex mt-8 space-x-12">
          <div className="text-center">
            <h2 className="text-4xl font-bold text-blue-600">3+</h2>
            <span className="text-sm text-gray-600">Years on the market</span>
          </div>
          <div className="text-center">
            <h2 className="text-4xl font-bold text-blue-600">56+</h2>
            <span className="text-sm text-gray-600">Projects delivered so far</span>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default AboutArea;
