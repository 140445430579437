import React from "react";

const ServiceSection = () => {
    return (
        <div className="section py-12 bg-gray-100">
            <div className="container mx-auto">
                <div className="section-heading mb-12 text-center">
                    {/* <span className="text-sm text-red-500 uppercase tracking-wider"> Service</span> */}
                    <h2 className="text-4xl font-bold text-gray-800">Design</h2>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                    <div
                        className="services-grid bg-white p-6 rounded-lg shadow-lg hover:shadow-2xl transform hover:scale-105 transition-transform duration-300"
                        data-sal="slide-up"
                        data-sal-duration="800"
                        data-sal-delay="100"
                        style={{ maxWidth: "350px", margin: "0 auto" }}
                    >
                        <div className="thumbnail mb-4 text-6xl text-indigo-500">
                            🎨
                        </div>
                        <div className="content">
                            <h5 className="text-2xl font-semibold text-gray-800 mb-2">
                                Logo &amp; Branding
                            </h5>
                            <p className="text-gray-700 mb-4">
                                Our logo and branding services help you create a strong and memorable brand identity. We craft logos that effectively represent your brand and resonate with your audience.
                            </p>
                            <p className="text-gray-600 mb-4">
                                Whether you're a startup or an established company, our branding solutions are tailored to your needs. We focus on creating designs that are both aesthetically pleasing and strategically effective.
                            </p>
                            {/* <button className="mt-4 bg-indigo-500 text-white py-2 px-4 rounded hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-indigo-500">
                                Learn More
                            </button> */}
                        </div>
                    </div>

                    <div
                        className="services-grid bg-white p-6 rounded-lg shadow-lg hover:shadow-2xl transform hover:scale-105 transition-transform duration-300"
                        data-sal="slide-up"
                        data-sal-duration="800"
                        data-sal-delay="200"
                        style={{ maxWidth: "350px", margin: "0 auto" }}
                    >
                        <div className="thumbnail mb-4 text-6xl text-green-500">
                            💻
                        </div>
                        <div className="content">
                            <h5 className="text-2xl font-semibold text-gray-800 mb-2">
                                Website Design
                            </h5>
                            <p className="text-gray-700 mb-4">
                                From simple portfolios to complex applications, our website design services ensure your site looks great on any device and provides a seamless user experience.
                            </p>
                            <p className="text-gray-600 mb-4">
                                We focus on responsive design, user-friendly interfaces, and high performance to ensure that your website not only looks impressive but also functions flawlessly.
                            </p>
                            {/* <button className="mt-4 bg-green-500 text-white py-2 px-4 rounded hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500">
                                Explore Services
                            </button> */}
                        </div>
                    </div>

                    <div
                        className="services-grid bg-white p-6 rounded-lg shadow-lg hover:shadow-2xl transform hover:scale-105 transition-transform duration-300"
                        data-sal="slide-up"
                        data-sal-duration="800"
                        data-sal-delay="300"
                        style={{ maxWidth: "350px", margin: "0 auto" }}
                    >
                        <div className="thumbnail mb-4 text-6xl text-pink-500">
                            🖌️
                        </div>
                        <div className="content">
                            <h5 className="text-2xl font-semibold text-gray-800 mb-2">
                                UI/UX Design
                            </h5>
                            <p className="text-gray-700 mb-4">
                                We specialize in creating intuitive and visually appealing user interfaces and experiences. Whether it's for a new app or a website redesign, our designs are user-focused and engaging.
                            </p>
                            <p className="text-gray-600 mb-4">
                                Our UI/UX design services ensure that every element is thoughtfully designed to enhance user interaction and satisfaction. We deliver designs that are both functional and visually captivating.
                            </p>
                            {/* <button className="mt-4 bg-pink-500 text-white py-2 px-4 rounded hover:bg-pink-600 focus:outline-none focus:ring-2 focus:ring-pink-500">
                                Get Started
                            </button> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ServiceSection;
