import React from 'react';

const QualityArea = () => (
  <section className="bg-gray-800 text-white py-16 relative">
    <div className="container mx-auto px-4">
      <div className="text-center mb-16">
        <span className="text-sm font-semibold uppercase text-red-400">Our Values</span>
        <h2 className="text-3xl md:text-4xl font-bold mt-2 mb-4 text-gray-100">Why Should You Choose Us ?</h2>
        <p className="opacity-80 text-gray-300">We’re a group of friendly, creative, and passionate individuals who love working together to serve our clients.</p>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        <div className="h-55 p-6 bg-gray-700 rounded-lg shadow-lg flex flex-col justify-between">
          <div>
            <div className="w-12 h-12 bg-blue-400 text-white flex items-center justify-center rounded-full mb-4">
              <span className="text-2xl font-bold">1</span>
            </div>
            <h5 className="text-xl font-semibold mb-4 text-white">UNDERSTANDING</h5>
          </div>
          <p className="text-gray-300">By thoroughly examining your business objectives, goals, and unique selling propositions (USPs), we can engage in a comprehensive brainstorming process.</p>
        </div>
        <div className="h-55 p-6 bg-gray-700 rounded-lg shadow-lg flex flex-col justify-between">
          <div>
            <div className="w-12 h-12 bg-blue-400 text-white flex items-center justify-center rounded-full mb-4">
              <span className="text-2xl font-bold">2</span>
            </div>
            <h5 className="text-xl font-semibold mb-4 text-white">RESEARCHING</h5>
          </div>
          <p className="text-gray-300">Understanding the market is key to success. By auditing both you and your competitors, we forge the best possible strategy for your online identity.</p>
        </div>
        <div className="h-55 p-6 bg-gray-700 rounded-lg shadow-lg flex flex-col justify-between">
          <div>
            <div className="w-12 h-12 bg-blue-400 text-white flex items-center justify-center rounded-full mb-4">
              <span className="text-2xl font-bold">3</span>
            </div>
            <h5 className="text-xl font-semibold mb-4 text-white">PLANNING</h5>
          </div>
          <p className="text-gray-300">We use our knowledge to produce detailed plans that describe where your new website, online store, or brand design fits into your larger digital marketing strategy.</p>
        </div>
        <div className="h-55 p-6 bg-gray-700 rounded-lg shadow-lg flex flex-col justify-between">
          <div>
            <div className="w-12 h-12 bg-blue-400 text-white flex items-center justify-center rounded-full mb-4">
              <span className="text-2xl font-bold">4</span>
            </div>
            <h5 className="text-xl font-semibold mb-4 text-white">SOLVING</h5>
          </div>
          <p className="text-gray-300">Sound technical development is the foundation of our products and services. Our developers are true tech specialists who can solve any problem we throw at them.</p>
        </div>
        <div className="h-55 p-6 bg-gray-700 rounded-lg shadow-lg flex flex-col justify-between">
          <div>
            <div className="w-12 h-12 bg-blue-400 text-white flex items-center justify-center rounded-full mb-4">
              <span className="text-2xl font-bold">5</span>
            </div>
            <h5 className="text-xl font-semibold mb-4 text-white">COMMITTING</h5>
          </div>
          <p className="text-gray-300">Crafting the best in user experience is our forte – we design with the user in mind. Everything we create is built to scale and prepared for future development.</p>
        </div>
        <div className="h-55 p-6 bg-gray-700 rounded-lg shadow-lg flex flex-col justify-between">
          <div>
            <div className="w-12 h-12 bg-blue-400 text-white flex items-center justify-center rounded-full mb-4">
              <span className="text-2xl font-bold">6</span>
            </div>
            <h5 className="text-xl font-semibold mb-4 text-white">TESTING</h5>
          </div>
          <p className="text-gray-300">There is no such thing as too much testing! Every link, button, heading, and form is fully trialed by real users, so there are no surprises on launch day.</p>
        </div>
      </div>
    </div>
  </section>
);

export default QualityArea;
