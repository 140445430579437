import React from 'react';
import bannerThumb from '../../images/banner-thumb-3.png'; // Adjust the path if necessary
import { Link } from 'react-router-dom';
const BreadcrumbArea = () => (
  <div className="breadcrumb-area breadcrumb-banner bg-gray-100 py-24 relative">
    <h1 class="sr-only">Best IT Company in Varanasi</h1>
    <p class="sr-only">Learn more about Masterwiz Technologies, the top digital transformation company in Varanasi, Uttar Pradesh. We are known for our innovative solutions, experienced team, and customer-centric approach.</p>

    <div className="container mx-auto px-4 flex flex-col lg:flex-row items-center justify-center text-center">
      {/* <div className="flex flex-col md:flex-row items-center"> */}
      <div
        className="section-heading heading-left text-gray-900 lg:w-1/2"
        data-sal="slide-right"
        data-sal-duration="1000"
        data-sal-delay="300"
      >

        <h1 className="title text-5xl font-extrabold mb-6 leading-tight">
          One of the Fastest Growing Agencies
        </h1>
        <p className="text-xl md:text-2xl">
          We design, develop, and deploy digital products worldwide with a focus on excellence.
        </p>
        <Link to="/contact">
          <button className="mt-8 px-8 py-3 bg-blue-600 text-white rounded-lg shadow-lg transform transition-transform duration-300 hover:scale-105 hover:bg-blue-500 hover:shadow-2xl">
            Know More
          </button>
        </Link>
      </div>
      <div className="md:w-2/3 flex justify-center">
        <img
          src={bannerThumb}
          alt=" Masterwiz Technologies Agency banner"
          className="w-full h-auto rounded-lg transform transition duration-500 hover:scale-105"
        />
      </div>
    </div>
    {/* </div> */}
  </div>
);

export default BreadcrumbArea;
